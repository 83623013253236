<template>
    <div v-if="model && model.HasModels">
        <h2>{{ props.title }}</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 items-center py-4">
            <div>
                <h3 class="mb-3">{{ $t("PartModel.Symbol") }}</h3>
                <div class="part-model-item">
                    <img v-if="model.SymbolPreviewUrl" :src="model.SymbolPreviewUrl" class="part-model-item__image" />
                    <span v-else>{{ $t("PartModel.CurrentlyNotAvailable") }}</span>
                </div>
            </div>
            <div>
                <h3 class="mb-3">{{ $t("PartModel.Footprint") }}</h3>
                <div class="part-model-item">
                    <img
                        v-if="model.FootprintPreviewUrl"
                        :src="model.FootprintPreviewUrl"
                        class="part-model-item__image" />
                    <span v-else>{{ $t("PartModel.CurrentlyNotAvailable") }}</span>
                </div>
            </div>
            <div>
                <h3 class="mb-3">{{ $t("PartModel.ThreeDModel") }}</h3>
                <div class="part-model-item">
                    <iframe
                        v-if="model.ModelPreviewIframeUrl"
                        :src="model.ModelPreviewIframeUrl"
                        height="100%"
                        width="100%"
                        allowfullscreen
                        mozallowfullscreen="true"
                        webkitallowfullscreen="true" />
                    <img
                        v-else-if="model.ModelPreviewUrl"
                        :src="model.ModelPreviewUrl"
                        class="part-model-item__image" />
                    <span v-else>{{ $t("PartModel.CurrentlyNotAvailable") }}</span>
                </div>
            </div>
        </div>
        <div v-if="hasDownloads">
            <button class="btn btn-bg-primary mx-auto" @click="openDownloadModal">
                {{ $t("PartModel.SelectDownloadFormat") }}
            </button>
        </div>
        <Dialog v-model:open="isDownloadModalOpen">
            <DialogScrollContent :id="props.source + '-model-download-modal'">
                <DialogHeader>
                    {{ $t("PartModel.SelectDownloadFormat") }}
                </DialogHeader>
                <fieldset v-if="threeDDownloads.length" class="download-options">
                    <legend>{{ $t("PartModel.ThreeDModel") }}</legend>
                    <div v-for="option in threeDDownloads" :key="option.Format" class="mt-1">
                        <input
                            :id="props.source + '-format-' + option.Format"
                            v-model="selectedDownloadFormat"
                            type="radio"
                            name="download-format"
                            :value="option.Format"
                            class="mr-2" />
                        <label :for="props.source + '-format-' + option.Format">{{ option.Name }}</label>
                    </div>
                </fieldset>
                <fieldset class="download-options">
                    <legend>{{ $t("PartModel.TwoDModel") }}</legend>
                    <div v-for="option in twoDDownloads" :key="option.Format" class="mt-1">
                        <input
                            :id="props.source + '-format-' + option.Format"
                            v-model="selectedDownloadFormat"
                            type="radio"
                            name="download-format"
                            :value="option.Format"
                            class="mr-2" />
                        <label :for="props.source + '-format-' + option.Format">{{ option.Name }}</label>
                    </div>
                </fieldset>

                <div v-if="showDownloadError" class="alert alert-danger mt-2">
                    {{ $t("PartModel.DownloadError") }}
                </div>

                <button
                    class="btn btn-bg-primary mt-2"
                    :disabled="selectedDownloadFormat === '' || isDownloading"
                    @click="downloadFile">
                    <span v-if="!isDownloading">{{ $t("PartModel.Download") }}</span>
                    <span v-if="isDownloading">{{ $t("PartModel.Downloading") }}</span>
                </button>
            </DialogScrollContent>
        </Dialog>
    </div>
</template>

<script setup lang="ts">
import type { PartModelResponse } from "~/models/part/PartModel";

import saveAs from "file-saver";

const props = defineProps({
    model: { type: Object as PropType<PartModelResponse>, required: true },
    source: { type: String, required: true },
    title: { type: String, required: true },
    partNumber: { type: String, default: "" },
    manufacturerName: { type: String, default: "" },
});

const isDownloadModalOpen = ref(false);
const isDownloading = ref(false);
const showDownloadError = ref(false);
const selectedDownloadFormat = ref("");

const twoDDownloads = computed(() => getDownloadOptions("2D"));
const threeDDownloads = computed(() => getDownloadOptions("3D"));
const hasDownloads = computed(() => Array.isArray(props.model.ExportFormats) && props.model.ExportFormats.length > 0);

const getDownloadOptions = (type: string) => {
    if (!props.model || !props.model.ExportFormats) {
        return [];
    }

    return props.model.ExportFormats.filter((x) => x.Type === type).sort((a, b) => a.Name.localeCompare(b.Name));
};

const openDownloadModal = () => {
    showDownloadError.value = false;
    isDownloadModalOpen.value = true;
};

async function snapmagicDownload() {
    isDownloading.value = true;
    showDownloadError.value = false;

    try {
        const response = await axios.post(
            "/api/part/model/export/snapmagic",
            {
                PartNumber: props.partNumber,
                ManufacturerName: props.manufacturerName,
                Format: selectedDownloadFormat.value,
            },
            { responseType: "json" }
        );

        if (response.status === 200 && response.data.Url) {
            const download = await axios.get(response.data.Url, { responseType: "arraybuffer" });
            if (download.status === 200) {
                saveAs(new Blob([download.data]), response.data.Filename);
            } else {
                showDownloadError.value = true;
            }
        } else {
            showDownloadError.value = true;
        }
    } catch (err) {
        console.error(err);
        showDownloadError.value = true;
    }

    isDownloading.value = false;
}

async function ulDownload() {
    isDownloading.value = true;
    showDownloadError.value = false;

    try {
        const response = await axios.post(
            "/api/part/model/export/ul",
            {
                PartNumber: props.partNumber,
                ManufacturerName: props.manufacturerName,
                PartUid: props.model.Uid,
                Format: selectedDownloadFormat.value,
                FormatName: props.model.ExportFormats.find((x) => x.Format === selectedDownloadFormat.value)?.Name,
            },
            { responseType: "arraybuffer" }
        );

        if (response.status === 200) {
            const contentDisposition = response.headers["content-disposition"];
            const filename = contentDisposition.match(/filename=(.*);/)[1];
            saveAs(new Blob([response.data]), filename);
        } else {
            showDownloadError.value = true;
        }
    } catch (err) {
        console.error(err);
        showDownloadError.value = true;
    }

    isDownloading.value = false;
}

const downloadFile = props.source === "ul" ? ulDownload : snapmagicDownload;
</script>

<style scoped>
.part-model-item {
    @apply border-solid border-2 border-gray-100 text-center p-4 h-[400px];
}

.part-model-item__image {
    @apply w-full h-full object-contain;
}

.download-options {
    @apply border-solid border-2 border-gray-100 p-3;
}
</style>
