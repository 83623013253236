<template>
    <div class="content-container">
        <div class="w-full px-12">
            <h1>{{ $t("PartModel.Title", [data?.ManufacturerName, data?.PartNumber]) }}</h1>
            <div class="flex my-8">
                <a :href="data?.PartDetailUrl" class="mr-8 w-18">
                    <PartImage size="lg" :part="data" />
                </a>
                <div class="mt-2">
                    <div class="font-bold">{{ data?.ManufacturerName }}</div>
                    <a :href="data?.PartDetailUrl" class="font-bold text-lg">{{ data?.PartNumber }}</a>
                    <p>{{ data?.Description }}</p>
                </div>
            </div>
            <PartModelGrid
                v-if="ULModel?.HasModels"
                title="Ultra Librarian"
                source="ul"
                :model="ULModel"
                :part-number="data?.PartNumber"
                :manufacturer-name="data?.ManufacturerName" />
            <PartModelGrid
                v-if="SMModel?.HasModels"
                title="SnapMagic"
                source="snapmagic"
                :model="SMModel"
                :part-number="data?.PartNumber"
                :manufacturer-name="data?.ManufacturerName" />
        </div>
    </div>
</template>

<script setup lang="ts">
const { public: config } = useRuntimeConfig();
const route = useRoute();
const { t } = useI18n();
const { company, partNumber } = route.params;

const { data, error } = await useFetchLocaleApi<PartModelsResponse>(`api/part/model`, {
    method: "post",
    body: {
        PartNumber: partNumber,
        MfrVirtualPath: company,
    },
});
if (error.value) {
    throw createError({
        statusCode: error.value?.statusCode || 500,
    });
}

if (data.value?.CanonicalPartModelUrl) {
    useHead({
        link: [
            {
                // The hid needs to match the hid from Nuxt i18n so we can override it
                hid: "i18n-can",
                rel: "canonical",
                href: `${config.baseUrl}${data.value.CanonicalPartModelUrl}`,
            },
        ],
    });
}

const ULModel = data.value?.Models.UltraLibrarianResponse;
const SMModel = data.value?.Models.SnapMagicResponse;

const title = computed(() => t("PartModel.Title", [data.value?.ManufacturerName, data.value?.PartNumber]));
const description = computed(() =>
    t("PartModel.MetaDescriptionFormat", [data.value?.ManufacturerName, data.value?.PartNumber])
);
useSeoMeta({ title, ogTitle: title, description, ogDescription: description });
</script>
